import { BrowserRouter, Route, Routes } from "react-router-dom";
import EditProfile from "./components/Profile/EditProfile.js";
import About from "./pages/About.js";
import EventPage from "./pages/EventPage.js";
import Homepage from "./pages/Homepage.js";
import Login from "./pages/Login.js";
import Membership from "./pages/Membership.js";
import Memories from "./pages/Memories.js";
import Register from "./pages/Register.js";
import UserProfile from "./pages/userProfile.js";
import PrivateRoutes from "./components/Routes/PrivateRoutes.js";
import ForgetPass from "./pages/ForgetPass.js";
import ConfirmPass from "./pages/ConfirmPass.js";
// import Membership from './pages/Membership.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/membership" element={<Membership />}></Route>
        <Route
          path="/userProfile"
          element={
            <PrivateRoutes>
              <UserProfile />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/editProfile"
          element={
            <PrivateRoutes>
              <EditProfile />
            </PrivateRoutes>
          }
        ></Route>
        <Route path="/memories/*" element={<Memories />}></Route>
        <Route path="/memories/:eventId" element={<EventPage />}></Route>
        <Route path="/forget-password" element={<ForgetPass />}></Route>
        <Route path="/confirm-password" element={<ConfirmPass />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
