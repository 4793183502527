import { Card, CardActions, Container, Slide, Typography, Grid } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";

const Memories = () => {
  const [eventData, setEventData] = useState([]);
  const navigate = useNavigate();
  const handleClick = (eventId) => {
    navigate(`/memories/${eventId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get("https://bpdacadmin.icicle.dev/api/eventData");
        const filteredData = response.data.filter(
          (item) => item.memories !== null || item.video_link !== null
        );
        setEventData(filteredData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div>
        <Container className="memory-page py-12">
          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Container
              className="pa-4"
              style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}
            >
              <Grid container spacing={3}>
                {eventData
                  .filter((data) => {
                    return data.memories !== null;
                  })
                  .map((item) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                      <Card className="ma-3" style={{ marginBottom: 20, borderRadius: 12 }}>
                        <div className="image-container">
                          <img
                            src={`https://bpdacadmin.icicle.dev/${item.image}`}
                            alt={item.title}
                            style={{ height: 200, width: "100%" }}
                            className="zoom-image"
                          />
                        </div>
                        <div className="text-center py-3" style={{ marginTop: "10px" }}>
                          <Typography variant="h5" className="font-weight-bold">
                            {item.title}
                          </Typography>
                        </div>
                        <CardActions>
                          <div
                            className="buttons-set"
                            style={{ display: "inline-block", margin: "auto" }}
                          >
                            <button
                              onClick={() => handleClick(item.id)}
                              className="bnt bnt-theme text-regular text-uppercase memories"
                            >
                              SEE MEMORIES
                            </button>
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </Slide>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Memories;
