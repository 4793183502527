import React from 'react';

export const SupportEmpowerment = () => (
  <div>
    <p>Create a nurturing and inclusive environment that acknowledges and addresses the unique challenges faced by Bangladeshi IMGs in Canada.</p>
    <p>Foster a sense of belonging, camaraderie, and professional growth through mentorship programs, networking opportunities, and tailored support systems.</p>
  </div>
);

export const AdvocacyRepresentation = () => (
  <div>
    <p>Vigorously advocate for the rights and interests of Bangladeshi IMGs within the Canadian healthcare system.</p>
    <p>Work diligently to eliminate biases and ensure equal opportunities in training, licensing, residency programs, and career advancement.</p>
    <p>Strive for increased representation in decision-making bodies and committees to influence policies that impact the Bangladeshi IMG community.</p>
  </div>
);

export const InformationResourceSharing = () => (
  <div>
    <p>Establish as a trusted source of information, providing comprehensive guidance on medical licensing processes, residency applications, and certification requirements.</p>
    <p>Ensure accessibility to educational resources and offer continuous support in navigating the intricacies of the Canadian healthcare system.</p>
    <p>Facilitate knowledge exchange through workshops, webinars, and a user-friendly online platform for seamless information sharing.</p>
  </div>
);

export const GlobalEngagement = () => (
  <div>
    <p>Actively participate in international relief efforts, demonstrating our commitment to global health and community welfare.</p>
    <p>Collaborate with organizations and stakeholders to contribute expertise and resources during disasters in Bangladesh, showcasing solidarity and a sense of global responsibility.</p>
    <p>Cultivate partnerships with international healthcare organizations to facilitate knowledge transfer and enhance the global impact of Bangladeshi IMGs.</p>
  </div>
);

export const BiasReductionInitiatives = () => (
  <div>
    <ul>
      <li>Actively implementing initiatives to address and reduce systemic biases through awareness campaigns, training programs, and collaboration with key stakeholders. Also, establishing a reporting mechanism for bias incidents to ensure a swift and effective response to instances of discrimination.</li>
    </ul>
  </div>
);


export const activities = [
  {
    id: 1,
    name: 'New IMG Orientation',
    description: `• Enhance the new IMG orientation program to provide a comprehensive and personalized guide through the intricacies of the Canadian healthcare landscape.
    
    • Develop informative materials and conduct interactive sessions to ensure IMGs are well-equipped with the knowledge and resources necessary for a successful transition.`
  },
  {
    id: 2,
    name: 'MCCQE1 Preparation in Bangladesh',
    description: `• Advocate for the establishment of an MCCQE1 examination center and a Visa Center in Dhaka, streamlining the examination process for Bangladeshi IMGs.
    
    • Collaborate with relevant authorities to create preparatory resources and support systems for IMGs undertaking the MCCQE1, promoting a higher success rate.`
  },
  
  {
    id: 3,
    name: 'Accreditation Promotion',
    description: `•	Actively engage in advocacy efforts to promote the accreditation of Bangladeshi education, contributing to the recognition of Bangladeshi degrees and qualifications in the Canadian context.
    
    •	Collaborate with educational institutions and accreditation bodies to facilitate a smoother integration of Bangladeshi qualifications into the Canadian healthcare system.`
  },
  {
    id: 4,
    name: 'Networking with Practicing Physicians',
    description: `•	Develop targeted networking events and platforms to facilitate meaningful connections between new Bangladeshi IMGs and practicing physicians in Canada.
    
    •	Establish mentorship circles and professional development opportunities to ensure a supportive network for ongoing guidance and career advancement.`
  },
  {
    id: 5,
    name: 'Mentoring Program',
    description: `•	Enhance the mentoring program by incorporating structured training for mentors, ensuring the effective transfer of knowledge and support for newly arrived IMGs.
    
    •	Regularly assess and update the program based on feedback and evolving needs within the IMG community.`
  },
  {
    id: 6,
    name: 'IMG Support Groups',
    description: `•	Organize regular support group sessions, providing a platform for IMGs to connect, share experiences, and receive emotional support.
    
    •	Expand the support groups to address specific challenges such as cultural adaptation, language proficiency, and professional development.`
  },
  {
    id: 7,
    name: 'Licensing and Residency Workshops',
    description: `•	Develop comprehensive workshops and information sessions covering all aspects of obtaining medical licensure and navigating residency applications.
    
    •	Provide ongoing support through one-on-one consultations and follow-up sessions to address individual concerns and progress.`
  },
  {
    id: 8,
    name: 'Cultural Competency Training',
    description: `•	Implement a robust cultural competency training program, encompassing not only adaptation to the local healthcare system but also patient-centered care and effective communication.
    
    •	Collaborate with cultural experts and healthcare professionals to tailor the training to the unique needs of IMGs.`
  },
  {
    id: 9,
    name: 'Community Outreach',
    description: `•	Expand community outreach initiatives to include diverse activities such as health fairs, free clinics, and targeted health education sessions.
    
    •	Foster partnerships with community organizations to better understand and address the healthcare needs of underserved populations.`
  },
  {
    id: 10,
    name: 'Continuing Medical Education (CME)',
    description: `•	Diversify CME programs to cover a wide range of topics, including clinical updates, healthcare system orientation, and professional development.
    
    •	Utilize various formats such as workshops, webinars, and conferences to accommodate different learning preferences and schedules.`
  },
  {
    id: 11,
    name: 'Job Placement Assistance',
    description: `•	Strengthen job placement assistance by forging partnerships with local healthcare institutions, recruiters, and medical associations.
    
    •	Provide personalized guidance on resume building, interview preparation, and professional networking to enhance the employability of IMGs.`
  },
  {
    id: 12,
    name: 'Advocacy and Policy Engagement',
    description: `•	Establish a dedicated advocacy committee to actively engage with regulatory bodies, medical associations, and government agencies.
    
    •	Develop evidence-based policy recommendations to support the integration and recognition of IMGs in the local healthcare system.`
  },
  {
    id: 13,
    name: 'Research and Publications',
    description: `•	Encourage and facilitate IMGs' involvement in research projects by providing resources, mentorship, and collaborative opportunities.
    
    •	Create platforms for showcasing and disseminating research findings, contributing to the broader medical community's knowledge base and addressing pertinent healthcare issues.`
  }
];