import React from "react";
import Header from "../components/Common/Header";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Axios from "axios";

const ForgetPass = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;

    try {
      const response = await Axios.post("https://bpdacadmin.icicle.dev/api/sendPasswordResetLink", {
        email,
      });
      if (response.status === 200 && response.statusText === "OK") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response.data.data,
          confirmButtonText: "Ok",
          confirmButtonColor: "#006837",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Email Not Send",
          text: "Something went wrong! Please Try Again",
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
        console.log();
      }

      // console.error("Login failed:", response);
    } catch (error) {
      console.error("Error submitting form:", error.response);
      // Handle errors
      Swal.fire({
        icon: "error",
        title: "Email Not Send",
        html: `
            ${error.response.data.message ? `<p>${error.response.data.messageP}</p>` : ""}
          `,
        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
    }
  };
  return (
    <section>
      <Header />
      <div className="memory-page">
        <div style={{ width: "350px", margin: "0px auto", textAlign: "center" }}>
          <h4 style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
            Please Enter Your Email
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="input-box email">
              <input
                style={{
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  fontSize: "16px",
                  border: "none",
                  borderBottom: "1px solid gray",
                  textAlign: "center",
                  marginTop: "20px",
                }}
                type="email"
                name="email"
                id="email"
                placeholder="Please Enter Your Email Address"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <button
                type="submit"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#006837",
                  color: "white",
                  fontSize: "16px",
                  outline: "none",
                  border: "none",
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                SUBMIT
              </button>
            </div>
          </form>
          <Link to={"/login"} className="admin-login">
            <p style={{ padding: "5px" }}>Back to login</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ForgetPass;
