import { Container } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";
import ProfileSidebar from "../components/Profile/ProfileSidebar";
import PrivateRoutes from "../components/Routes/PrivateRoutes";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [user, setUser] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [formDataQ, setFormDataQ] = useState({
    newcomer: "",
    enrolledInResidency: "",
    practiceReadyPathway: "",
    completedLicensingExams: "",
    Bangladeshi_medical: "",
    medical_college: "",
    year_of_graduation: "",
    practice_ph_dn: "",
    allied_health: "",
    specify_industry: "",
  });

  const { name, email, address, phone, working_place } = user;

  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserData = localStorage.getItem("user");
    if (storedUserData) {
      // Parse the JSON data
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      fetch("https://bpdacadmin.icicle.dev/api/getQuestionnaire")
        .then((res) => res.json())
        .then((data) => {
          const isUserInQuestionnaire = data.some((item) => item?.user_id === userData?.id);
          setShowPopup(!isUserInQuestionnaire);
        })
        .catch((error) => {
          console.error("Error Fetching user data: ", error);
        });
    }
  }, [userData]);

  // load data for updated
  useEffect(() => {
    if (userData) {
      fetch("https://bpdacadmin.icicle.dev/api/userData")
        .then((res) => res.json())
        .then((data) => {
          const user = data.find((item) => item?.email === userData?.email);
          setUser(user);
        })
        .catch((error) => {
          console.error("Error Fetching user data: ", error);
        });
    }
  }, [userData]);

  const handleChange = (question, answer) => {
    setFormDataQ((prevData) => ({
      ...prevData,
      [question]: answer,
    }));
  };

  const handleSubmitQ = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.post("https://bpdacadmin.icicle.dev/api/Questionnaire", {
        ...formDataQ,
        user_id: userData.id,
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Successfully done!",
          text: "Thanks for your feedback.",
          confirmButtonText: "Ok",
          confirmButtonColor: "#006837",
        }).then(() => {
          setShowPopup(false);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.msg,
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
        console.error("Registration failed:", response.data.msg);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Header />
      <Container className="memory-page py-12">
        <ProfileSidebar />
        {showPopup && (
          <div>
            <div className="popup">
              <form onSubmit={handleSubmitQ} className="popup-form">
                <h3 className="popup-title text-center mb-4">New Member Questionnaire</h3>
                <div className="mb-3" style={{ marginTop: "40px", marginBottom: "10px" }}>
                  <label className="popup-label">Are you a newcomer in Canada?</label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("newcomer", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="mb-3" style={{ marginBottom: "10px" }}>
                  <label className="popup-label">
                    Are you currently enrolled in a residency course?
                  </label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("enrolledInResidency", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="mb-3" style={{ marginBottom: "10px" }}>
                  <label className="popup-label">Are you a practicing physician/dentist?</label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("practice_ph_dn", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="mb-3" style={{ marginBottom: "10px" }}>
                  <label className="popup-label">Are you following a practice-ready pathway?</label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("practiceReadyPathway", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="mb-3" style={{ marginBottom: "10px" }}>
                  <label className="popup-label">Have you completed your licensing exams?</label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("completedLicensingExams", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="mb-3" style={{ marginBottom: "10px" }}>
                  <label className="popup-label">Are you open to allied health?</label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("allied_health", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div
                  className={`${formDataQ?.allied_health === "Yes" ? "block" : "hidden"}`}
                  style={{ marginBottom: "10px" }}
                >
                  <label className="popup-label">
                    Please specify the industry you would like to explore -
                  </label>
                  <input
                    className="form-select"
                    style={{ marginTop: "10px", width: "75%" }}
                    onChange={(e) => handleChange("specify_industry", e.target.value)}
                    type="text"
                  />
                </div>

                <div className="mb-3" style={{ marginBottom: "10px" }}>
                  <label className="popup-label">Are you a Bangladeshi medical graduate?</label>
                  <select
                    required
                    className="form-select"
                    onChange={(e) => handleChange("Bangladeshi_medical", e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div
                  className={`${formDataQ?.Bangladeshi_medical === "Yes" ? "block" : "hidden"}`}
                  style={{ marginBottom: "10px" }}
                >
                  <label className="popup-label">
                    Which medical/dental college did you graduate from?
                  </label>
                  <input
                    className="form-select"
                    style={{ marginTop: "10px", width: "75%" }}
                    onChange={(e) => handleChange("medical_college", e.target.value)}
                    type="text"
                  />
                </div>

                <div
                  className={`${formDataQ?.Bangladeshi_medical === "Yes" ? "block" : "hidden"}`}
                  style={{ marginBottom: "30px" }}
                >
                  <label className="popup-label">What is your year of graduation?</label>
                  <input
                    className="form-select"
                    onChange={(e) => handleChange("year_of_graduation", e.target.value)}
                    type="text"
                  />
                </div>

                <button className="popup-submit btn btn-primary" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}

        <div className="col-lg-8 col-md-8 vstack gap-4">
          <div className="card profile-info">
            <div className="card-header border-0 pb-0">
              <div className="text-center">
                <h4 className="card-title" style={{ margin: "20px", fontWeight: "bold" }}>
                  User Information Form
                </h4>
              </div>
            </div>
            <div className="card-body">
              <form>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={user ? name : ""}
                    placeholder="Enter your username"
                    disabled
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                    value={user ? email : ""}
                    disabled
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <textarea
                    className="form-control"
                    id="address"
                    placeholder="Enter your address"
                    rows="3"
                    value={user ? address : ""}
                    disabled
                  ></textarea>
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    placeholder="Enter your phone number"
                    value={user ? phone : ""}
                    disabled
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="workplace"
                    placeholder="Enter your workplace"
                    value={user ? working_place : ""}
                    disabled
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <PrivateRoutes />
      </Container>
      <Footer />
    </>
  );
};

export default UserProfile;
