import Axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../components/Common/Header";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.post("https://bpdacadmin.icicle.dev/api/userLogin", {
        email,
        password,
      });

      if (response.status === 200 && response.data.status === "ok") {
        if (response.data.user && response.data.user.email_verified === "yes") {
          const token = response.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: response.data.msg,
            confirmButtonText: "Ok",
            confirmButtonColor: "#006837",
          }).then(() => {
            navigate("/userProfile");
          });
        } else {
          // User email not verified
          Swal.fire({
            icon: "warning",
            title: "Email Not Verified",
            text: "Please verify your email before logging in.",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FFD700",
          });
        }
      } else {
        // Login failed
        Swal.fire({
          icon: "error",
          title: "Login failed",
          text: "Please check your credentials.",
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
        //console.error("Login failed:", response.data);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error submitting form",
        html: `
            ${error.response.data.error ? `<p>${error.response.data.error}</p>` : ""}
          `,
        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
    }
  };

  // const clickAdminLogin = () => {
  //   window.location.href = "https://bpdacadmin.icicle.dev/login";
  // };

  // const handleWebmailLogin = () => {
  //   window.location.href = "https://bpdac.ca:2096/";
  // }
  return (
    <>
      <Header />
      <div className="content-wrapper">
        <div className="account-page login text-center">
          <div className="container">
            <div className="account-title">
              <h4 className="heading-light">MEMBER LOG IN</h4>
            </div>
            <div className="account-content">
              <form onSubmit={handleSubmit}>
                <div className="input-box email">
                  <input
                    style={{ outline: "none" }}
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-box password">
                  <input
                    style={{ outline: "none" }}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={{display: "flex", gap: "20px", justifyContent: "center", marginTop: "10px"}}>
                  <button type="submit" className="loginButton bnt bnt-theme text-regular text-uppercase">
                    LOG IN
                  </button>
                  {/* <button style={{backgroundColor: "red", padding: "16px 14px", color: "white", fontSize: "16px", display: "inline-block", border: "none"}} className="bnt text-regular text-uppercase" onClick={handleWebmailLogin}>
                    WEBMAIL
                  </button> */}
                  <a
                    href="https://bpdac.ca:2096/"
                    target="_brank"
                    rel="noreferrer"
                    className="webmailLink"
                  >
                    <div style={{backgroundColor: "red", padding: "16px 14px", color: "white", fontSize: "16px", display: "inline-block", border: "none"}} className="text-regular text-uppercase">WEBMAIL</div>
                  </a>

                </div>
                
                <div style={{ marginTop: "20px" }}>
                  <Link to={"/forget-password"} className="admin-login">
                    Forget Password
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    justifyContent: "center",
                    marginTop: "5px",
                  }}
                >
                  <div>Please login as an</div>

                  <a
                    href="https://bpdacadmin.icicle.dev/login"
                    target="_brank"
                    rel="noreferrer"
                    className="admin-login"
                  >
                    <div className="admin-login">Admin</div>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
