import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [transportation, setTransportation] = useState("");
  const [joined, setJoined] = useState(false);
  const [totalUserCount, setTotalUserCount] = useState([]);
  const [adults, setAdults] = useState(0);
  const [age512, setAge512] = useState(0);
  const [age05, setAge05] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get("https://bpdacadmin.icicle.dev/api/eventData");
        setEventData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get("https://bpdacadmin.icicle.dev/api/event_user_counts");
        setTotalUserCount(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [joined]);

  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthIndex];
  };

  const findNearestEvent = () => {
    const currentDate = new Date();

    // Initialize variables to track the nearest event
    let nearestEvent = null;
    let nearestTimeDifference = Infinity;

    // Iterate through the events
    for (const event of eventData) {
      const eventStartDate = new Date(event.start_date);

      // Calculate the time difference in milliseconds
      const timeDifference = eventStartDate.getTime() - currentDate.getTime();

      // Check if the event is in the future and has a smaller time difference
      if (timeDifference > 0 && timeDifference < nearestTimeDifference) {
        nearestEvent = event;
        nearestTimeDifference = timeDifference;
      }
    }

    return nearestEvent;
  };

  const handleJoinNow = () => {
    //const nearestEvent = findNearestEvent();
    if (localStorage.getItem("user")) {
      setShowPopup(true);
    } else {
      navigate("/login");
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Call the function to find the nearest event
  const nearestEvent = findNearestEvent();
  const nearestEventStartDate = new Date(nearestEvent?.start_date);
  const nearestYear = nearestEventStartDate.getFullYear();
  const nearestMonthIndex = nearestEventStartDate.getMonth();
  const nearestMonthName = getMonthName(nearestMonthIndex);
  const nearestDay = nearestEventStartDate.getDate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const nearestEvent = findNearestEvent();

    const calculatedTotalAmount = adults * 35 + age512 * 25 + age05 * 0;

    // Prepare data for the API request
    const userFromLocal = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const parsedUser = JSON.parse(userFromLocal);

    const formData = {
      user_id: parsedUser.id, // Assuming the user object has an 'id' property
      event_id: nearestEvent.id,
      adult: adults,
      midAge: age512,
      child: age05,
      transport: transportation,
      total_Amount: calculatedTotalAmount,
    };

    try {
      const response = await Axios.post(
        "https://bpdacadmin.icicle.dev/api/eventRegister",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.request.status === 200 && response.request.statusText === "OK") {
        console.log("Event registration successful");
        Swal.fire({
          icon: "success",
          title: "successful!",
          text: "Event registration successful",
          confirmButtonText: "Ok",
          confirmButtonColor: "#006837",
        }).then(() => {
          setJoined(true);
          setShowPopup(false);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Event registration failed",
          text: response.data.msg,
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
        console.error("Event registration failed:", response.data.msg);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error submitting event registration",
        text: "Something went wrong!",
        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
      console.error("Error submitting event registration:", error);
    }
    
    //setShowPopup(false);
  };
  return (
    <>
      {(nearestEvent === null) ? (
       <></>
      ): (
        <div className="upcoming-event">
          <div className="container">
            <div className="row">
              <div className="area-img col-md-5 col-sm-12 col-xs-12" style={{ paddingTop: "50px" }}>
                <img
                  className="img-responsive animated zoomIn"
                  src={`https://bpdacadmin.icicle.dev/public/${nearestEvent?.image}`}
                  alt=""
                />
              </div>
              <div className="area-content col-md-7 col-sm-12 col-xs-12" style={{ paddingTop: "50px" }}>
                <div className="area-top">
                  <div className="row">
                    <div className="col-sm-10 col-xs-9">
                      <h5
                        className="heading-light no-margin animated fadeInRight"
                        style={{ color: "red" }}
                      >
                        UPCOMING EVENT
                      </h5>
                      <h2 className="heading-bold animated fadeInLeft">{nearestEvent?.title}</h2>
                      <h4
                        className="animated fadeInLeft mt-4"
                        style={{ textAlign: "justify", hyphens: "auto" }}
                      >
                        {nearestEvent?.description}
                      </h4>
                      <div style={{ marginTop: "15px" }}>
                        <span className="icon map-icon mt-4"></span>
                        <span
                          className="text-place text-light animated fadeInRight"
                          style={{ color: "red" }}
                        >
                          {nearestEvent?.venue}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-2 col-xs-3">
                      <div className="area-calendar calendar animated slideInRight">
                        <span className="day text-bold">{nearestDay}</span>
                        <span className="month text-light">{nearestMonthName}</span>
                        <span className="year text-light bg-year">{nearestYear}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="area-bottom">
                  <div id="time" className="pull-left animated slideInLeft"></div>

                  <button
                    className="bnt bnt-theme join-now1 pull-right animated fadeIn"
                    style={{ color: "red" }}
                  >
                    {totalUserCount &&
                      totalUserCount.length > 0 &&
                      totalUserCount.reduce((total, event) => {
                        if (event.event_id === nearestEvent?.id) {
                          return total + event.total_person;
                        }
                        return total;
                      }, 0)}
                  </button>
                  <button
                    className="bnt bnt-theme join-now1 pull-right animated fadeIn"
                    style={{ borderRight: "1px solid white" }}
                    onClick={handleJoinNow}
                    disabled={joined} // Disable the button if joined is true
                  >
                    {joined ? "Joined" : "Join Now"}
                  </button>

                  {showPopup && (
                    <div className="popup">
                      <span
                        className="close-button"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "15px",
                          cursor: "pointer",
                          fontSize: "40px",
                          color: "white",
                        }}
                        onClick={handleClosePopup}
                      >
                        &times;
                      </span>
                      <form className="popup-form" onSubmit={handleFormSubmit}>
                        <h3 className="popup-title text-center mb-4">{nearestEvent?.title}</h3>

                        <div className="row align-items-center" style={{ marginBottom: "8px" }}>
                          <label
                            htmlFor="adults"
                            className="col-lg-5 col-md-6 col-sm-5 col-6 popup-label"
                          >
                            Adults:
                          </label>
                          <div className="col-lg-7 col-md-6 col-sm-7 col-6">
                            <input
                              type="number"
                              id="adults"
                              className="form-control"
                              value={adults}
                              onChange={(e) => setAdults(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row align-items-center" style={{ marginBottom: "8px" }}>
                          <label
                            htmlFor="age512"
                            className="popup-label col-lg-5 col-md-6 col-sm-5 col-6"
                          >
                            Age(5-12):
                          </label>
                          <div className="col-lg-7 col-md-6 col-sm-7 col-6">
                            <input
                              type="number"
                              id="age512"
                              className="form-control"
                              value={age512}
                              onChange={(e) => setAge512(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row align-items-center" style={{ marginBottom: "8px" }}>
                          <label
                            htmlFor="age05"
                            className="popup-label col-lg-5 col-md-6 col-sm-5 col-6"
                          >
                            Age(0-5):
                          </label>
                          <div className="col-lg-7 col-md-6 col-sm-7 col-6">
                            <input
                              type="number"
                              id="age05"
                              className="form-control"
                              value={age05}
                              onChange={(e) => setAge05(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row align-items-center" style={{ marginBottom: "8px" }}>
                          <label
                            htmlFor="totalAmount"
                            className="popup-label col-lg-5 col-md-6 col-sm-5 col-6"
                          >
                            Total Amount:
                          </label>
                          <div className="col-lg-7 col-md-6 col-sm-7 col-6">
                            <input
                              type="text"
                              id="totalAmount"
                              className="form-control"
                              readOnly
                              value={"$ " + (adults * 35 + age512 * 25 + age05 * 0).toString()}
                            />
                          </div>
                        </div>

                        <div className="mb-3 row align-items-center" style={{ marginBottom: "8px" }}>
                          <label
                            htmlFor="transportation"
                            className="popup-label col-lg-5 col-md-6 col-sm-5 col-6"
                          >
                            Transportation:
                          </label>
                          <div className="col-lg-7 col-md-6 col-sm-7 col-6">
                            <select
                              id="transportation"
                              className="form-control"
                              value={transportation}
                              onChange={(e) => setTransportation(e.target.value)}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              <option value="own-vehicle">Own Vehicle</option>
                              <option value="no-vehicle">No Vehicle</option>
                            </select>
                          </div>
                        </div>

                        <button className="popup-submit btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> 
      )}
    </>   
  );
};
export default Events;
