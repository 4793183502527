import React from "react";

const Newsletter = () => {
    return (
        <div className="newsletter newsletter-parallax">
           <div className="container">
               <div className="newsletter-wrapper text-center">
                   <div className="newsletter-title">
                    
                   </div>
                   <form name="subscribe-form" target="_blank" className="form-inline">
                     
                   </form>
               </div>
           </div>
        </div>
    );
}
export default Newsletter;