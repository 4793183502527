import React from "react";

const History = () => {
  return (
    <>
      <div className="our-history">
        <div className="container">
          <div className="title-page text-center">
            <h2 className="text-regular">Our Journey</h2>
            <p className="text-light">
              Thanks to the support of all members, we're gaining momentum. Our task is to create a
              resilient professional community built on faith, care, and mutual support.
            </p>
          </div>
          <div className="history-content">
            <ul className="list-history text-center">
              <li>
                <span className="history-title text-light">
                  BPDAC
                  <br /> launched
                </span>
                <span className="history-dot">
                  {" "}
                  <span></span>
                </span>
                <span className="history-year">April 2022</span>
              </li>

              <li>
                <span className="history-title">
                  Summer <br /> Get together
                </span>
                <span className="history-dot">
                  <span></span>{" "}
                </span>
                <span className="history-year">August 2022</span>
              </li>
              {/* <li>
                                <span className="history-title">Academic Discussion <br/> Launched</span>
                                <span className="history-dot"><span></span> </span>
                                <span className="history-year">September 2022</span>
                            </li> */}
              <li>
                <span className="history-title">
                  Pitha Festival <br /> Launched
                </span>
                <span className="history-dot">
                  {" "}
                  <span></span>
                </span>
                <span className="history-year">January 2023</span>
              </li>
              <li>
                <span className="history-title">
                  Summer <br />
                  Picnic
                </span>
                <span className="history-dot">
                  {" "}
                  <span></span>
                </span>
                <span className="history-year">June 2023</span>
              </li>
              <li>
                <span className="history-title">
                  BPDAC Website
                  <br /> Launched
                </span>
                <span className="history-dot">
                  {" "}
                  <span></span>
                </span>
                <span className="history-year">December 2023</span>
              </li>
              <li>
                <span className="history-title">
                  Upcoming <br /> Winter Event
                </span>
                <span className="history-dot">
                  {" "}
                  <span></span>
                </span>
                <span className="history-year">February 2024</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
