import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Common/Header";
import Swal from "sweetalert2";
import Axios from "axios";

const ConfirmPass = () => {
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: true,
    message: "",
  });

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/;
    return regex.test(password);
  };

  const handlePasswordBlur = (e) => {
    const password = e.target.value;
    const isValid = validatePassword(password);
    setPasswordValidation({
      isValid,
      message: isValid
        ? ""
        : "Password must be one letter, one digit, One Special Character and a minimum length of 7 characters.",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const password_confirmation = e.target.confirm_password.value;

    //Token get from url
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    try {
      const response = await Axios.post("https://bpdacadmin.icicle.dev/api/resetPassword", {
        email,
        resetToken: token,
        password,
        password_confirmation,
      });

      if (response.status === 200 && response.statusText === "OK") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your Password is Reset Successfully",
          confirmButtonText: "Ok",
          confirmButtonColor: "#006837",
        });
      } else {
        // Login failed
        Swal.fire({
          icon: "error",
          title: "Sorry!",
          text: response.data.data,
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
        // console.error("Error Message", response);
      }
    } catch (error) {
      //   console.error("Error submitting form:", error.response);
      // Handle errors
      Swal.fire({
        icon: "error",
        title: "Sorry!",
        html: `
              ${error.response.data.message ? `<p>${error.response.data.message}</p>` : ""}
            `,
        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
    }
  };
  return (
    <section>
      <Header />
      <div className="memory-page">
        <div style={{ width: "350px", margin: "0px auto", textAlign: "center" }}>
          <h4 style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
            Please Enter Your Password
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="input-box email">
              <input
                style={{
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  fontSize: "20px",
                  border: "none",
                  borderBottom: "1px solid gray",
                  textAlign: "center",
                  marginTop: "20px",
                }}
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
              />
            </div>
            <div className="input-box password">
              <input
                style={{
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  fontSize: "20px",
                  border: "none",
                  borderBottom: "1px solid gray",
                  textAlign: "center",
                  marginTop: "20px",
                }}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onBlur={handlePasswordBlur}
              />
              {!passwordValidation.isValid && (
                <div className="validation-message color-red" style={{ marginBottom: "10px" }}>
                  {passwordValidation.message}
                </div>
              )}
            </div>
            <div className="input-box confirm_password">
              <input
                style={{
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  fontSize: "20px",
                  border: "none",
                  borderBottom: "1px solid gray",
                  textAlign: "center",
                  marginTop: "20px",
                }}
                type="password"
                name="confirm_password"
                id="confirm_password"
                placeholder="Confirm Password"
              />
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <button
                type="submit"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#006837",
                  color: "white",
                  fontSize: "16px",
                  outline: "none",
                  border: "none",
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                SUBMIT
              </button>
            </div>
          </form>
          <Link to={"/"} className="admin-login">
            <p style={{ padding: "5px" }}>Back to Home</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ConfirmPass;
