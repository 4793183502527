import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    description: "",
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send a POST request to the API
    axios
      .post("https://bpdacadmin.icicle.dev/api/contact", formData)
      .then((response) => {
        if (response.request.status === 200 && response.request.statusText === "OK") {
          console.log("contact us successfully sent");
          Swal.fire({
            icon: "success",
            title: "successful!",
            text: "Form sent successfully",
            confirmButtonText: "Ok",
            confirmButtonColor: "#006837",
          }).then(() => {
            toggleModal();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Contact us failed",
            text: response.data.msg,
            confirmButtonText: "Ok",
            confirmButtonColor: "red",
          });
          console.error("Contact us failed:", response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        Swal.fire({
          icon: "error",
          title: "Error submitting form",
          text: "Something went wrong!",
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
      });
  };

  return (
    <div className="footer-wrapper type2">
      <footer className="foooter-container">
        <div className="container">
          <div className="footer-middle" style={{ textAlign: "center" }}>
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12 animated footer-col">
                <div className="contact-footer">
                  <div
                    className="logo-footer"
                    style={{ display: "block", textAlign: "center", width: "100%" }}
                  >
                    <a href="#." style={{ display: "inline-block" }}>
                      <img
                        src="assets/images/BPDA-05.png"
                        style={{ display: "inline-block" }}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="contact-desc">
                    <p className="text-light">BPDAC-3456 Danforth Ave, Toronto, ON M1L 1E1</p>
                  </div>
                  <div className="contact-phone-email">
                    <span className="contact-email">
                      <a href="mailto:info@bpdac.ca">info@bpdac.ca</a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-12  col-xs-12 animated footer-col">
                <div className="links-footer">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <h6 className="heading-bold">USEFUL LINKS</h6>
                      <ul className="list-unstyled no-margin">
                        <li>
                          <Link to='/about'>ABOUT US</Link>
                        </li>
                        <li>
                          <Link to='/memories'>GALLERY</Link>
                        </li>
                        <li>
                          <Link to='/membership'>MEMBER OPPORTUNITY</Link>
                        </li>
                      </ul>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                      <h6 className="heading-bold">SUPPORT</h6>
                      <ul className="list-unstyled no-margin">                  
                        <li>
                          <a href="#." onClick={toggleModal}>CONTACT US</a>
                        </li>
                        <li>
                          <a href="mailto:info@bpdac.ca">MAIL TO ORGANIZER</a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/share/eX3UPDbDxMxpibjs/?mibextid=K35XfP"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SOCIAL
                          </a>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12 animated footer-col">
                <div className="links-social">
                  {/* <div className="login-dashboard">
                                    <a href="#" className="bg-color-theme text-center text-regular">Contact With Us</a>
                                </div> */}
                  <div className="contact-button">
                    <button
                      className="bnt bnt-theme text-regular text-uppercase"
                      onClick={toggleModal}
                    >
                      Contact With Us
                    </button>
                  </div>

                  {showModal && (
                    <div className="contact_popup">
                      <span className="contact-close" onClick={toggleModal}>
                        &times;
                      </span>
                      <form className="contact-form" onSubmit={handleSubmit}>
                        <h3 className="popup-title">Contact Us</h3>
                        <div className="input-form">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Your Name"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.name}
                            required
                          />
                        </div>
                        <div className="input-form">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Your Email"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.email}
                            required
                          />
                        </div>
                        <div className="input-form">
                          <input
                            type="text"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.subject}
                            required
                          />
                        </div>
                        <div className="input-form">
                          <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            placeholder="Description"
                            rows="3"
                            onChange={handleChange}
                            value={formData.description}
                          />
                        </div>
                        <button className="contact-submit" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  )}

                  <ul className="list-inline text-center">
                    <li>
                      <a href="/">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/share/eX3UPDbDxMxpibjs/?mibextid=K35XfP"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom text-center">
            <a href="https://www.iciclecorporation.com/" target="_blank" rel="noreferrer">
              <p className="copyright text-light" style={{ textAlign: "end" }}>
                Powered by Icicle Corporation.
              </p>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
