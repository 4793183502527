import React from "react";
import Slider from "react-slick";

const Sliders = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,           // Enable autoplay
        autoplaySpeed: 2000  
    };

    return (
         <Slider {...settings}>
            
            <div className="slider-hero">
                <div className="item slider-item"
                    style={{
                    height: '650px',
                    maxWidth: '100%',
                    backgroundImage: 'url("/assets/images/slider2.jpeg")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',

                }} />
            </div>
            <div className="slider-hero">
                <div className="item slider-item"
                    style={{
                    height: '650px',
                    maxWidth: '100%',
                    backgroundImage: 'url("/assets/images/HomePageImage.jpg")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',

                }} />
            </div>
        <div className="slider-hero">
                <div className="item slider-item"
                    style={{
                    height: '650px',
                    maxWidth: '100%',
                    backgroundImage: 'url("/assets/images/aboutus3.jpeg")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',

                }} />
            </div>
            
            
       </Slider>


    );
}

export default Sliders;