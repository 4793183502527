import { Container, Grid } from "@material-ui/core";
import React from "react";

const LatestEventWithMemories = () => {
  return (
    <Container className="py-12" style={{ paddingTop: "20px" }}>
      <>
        <h3
          className="heading-regular"
          variant="h4"
          align="center"
          style={{
            paddingBottom: "20px",
            color: "red",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {/* {latestEvent.title} */}
          BPDAC at a Glance
        </h3>
        <Grid container spacing={3}>
          {[...Array(9)].map((e, i) => {
            return (
              <Grid key={i} item xs={12} sm={6} md={4} lg={4}>
                <div className="image-container">
                  <div style={{ width: "100%", height: "250px" }}>
                    <img
                      src={`/assets/images/atGlance${i + 1}.jpg`}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      alt={`Memory ${i + 1}`}
                      className="zoom-image"
                    />
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </>
    </Container>
  );
};

export default LatestEventWithMemories;
