import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  };
  const clickLogin = () => {
    navigate("/login");
  };

  const handleProfile = () => {
    navigate("/userProfile");
  };

  const isUserLoggedIn = !!localStorage.getItem("user");

  return (
    <div className="header-wrapper header-position">
      <header id="header" className="container-header type1">
        <div className="top-nav">
          <div className="container">
            <div className="row">
              <div className="top-left col-sm-6 hidden-xs">
                <ul className="list-inline">
                  <li>
                    <a href="mailto:info@bpdac.ca">
                      <span className="icon mail-icon"></span>
                      <span className="text">info@bpdac.ca</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+16475459250">
                      <span className="icon phone-icon"></span>
                      <span className="text">+1 (647) 545 9250</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="top-right col-sm-6 col-xs-12">
                <ul className="list-inline">
                  <li className="top-search">
                    <form className="navbar-form search no-margin no-padding">
                      <input
                        type="text"
                        name="q"
                        className="form-control input-search"
                        placeholder="search..."
                        autoComplete="off"
                      />
                      <button type="submit" className="lnr lnr-magnifier"></button>
                    </form>
                  </li>

                  <li className="login">
                    {isUserLoggedIn ? (
                      <div className="logout-profile">
                        <button
                          onClick={handleLogout}
                          className="bnt bnt-theme text-regular text-uppercase"
                        >
                          Logout
                        </button>
                        <button
                          onClick={handleProfile}
                          className="bnt bnt-theme text-regular text-uppercase"
                          style={{ marginLeft: "10px" }}
                        >
                          My Profile
                        </button>
                      </div>
                    ) : (
                      // If user is not logged in, show login button
                      <div>
                        <button
                          onClick={clickLogin}
                          className="bnt bnt-theme text-regular text-uppercase"
                        >
                          Login
                        </button>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-middle">
          <div className="container">
            <div className="logo hidden-sm hidden-xs">
              <a href="/">
                {" "}
                <img width={200} src="/assets/images/BPDA-03.png" alt="logo" />
              </a>
            </div>
            <div className="menu">
              <nav>
                <ul className="nav navbar-nav">
                  <li>
                    <Link to="/about">ABOUT US</Link>
                  </li>
                  <li>
                    <Link to="/memories">EVENTS MEMORIES</Link>
                  </li>

                  <li>
                    <Link to="/register">BE A MEMBER</Link>
                  </li>
                  <li>
                    <Link to="/membership">MEMBER OPPORTUNITY</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="area-mobile-content visible-sm visible-xs">
              <div className="logo-mobile">
                <a href="/">
                  {" "}
                  <img className="logo-image-mobile" src="/assets/images/BPDA-03.png" alt="logo" />
                </a>
              </div>
              <div className="mobile-menu mean-container">
                <div className="mean-bar">
                  <span
                    onClick={() => setShow((prev) => !prev)}
                    className="meanmenu-reveal mean-menu-tootle"
                    style={{ right: "0", left: "auto" }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <nav className="mean-nav">
                    <ul className="nav navbar-nav mob-1" style={{ display: !show && "none" }}>
                      <li>
                        <Link to="/about">ABOUT US</Link>
                      </li>
                      <li>
                        <Link to="/memories">EVENTS MEMORIES</Link>
                      </li>

                      <li>
                        <Link to="/register">BE A MEMBER</Link>
                      </li>
                      <li className="mean-last">
                        <Link to="/membership">MEMBER OPPORTUNITY</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
