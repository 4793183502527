/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoutes = ({ children }) => {
  const useerData = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  if (useerData && useerData.id) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};

export default PrivateRoutes;
