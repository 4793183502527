/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import imagepic from "../../../public/assets/images/profile-img.jpg";

const ProfileSidebar = () => {
  const [userData, setUserData] = useState(null);
  const [user, setUser] = useState([]);

  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserData = localStorage.getItem("user");

    if (storedUserData) {
      // Parse the JSON data
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, []);
  useEffect(() => {
    if (userData) {
      fetch("https://bpdacadmin.icicle.dev/api/userData")
        .then((res) => res.json())
        .then((data) => {
          // console.log(data, 18)
          const user = data.find((item) => item?.email === userData?.email);
          // const user = data.find(item => console.log(item.email, 34));
          // console.log(userData.email, 35)
          setUser(user);
        })
        .catch((error) => {
          console.error("Error Fetching user data: ", error);
        });
    }
  }, [userData]);
  return (
    <>
      <div className="col-lg-12 position-relative">
        <img
          src="assets/images/profilecover.jpg"
          style={{ height: "250px", width: "100%" }}
          alt="Cover Image"
        />
        <img
          className="profile-image"
          src={
            user?.image
              ? `https://bpdacadmin.icicle.dev/public/${user.image}`
              : "assets/images/profile-img.jpg"
          }
          alt="Profile Image"
        />
      </div>
      <div className="col-lg-4 col-md-4">
        <nav className="navbar navbar-expand-lg mx-0">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasSideNavbar">
            <div
              className="offcanvas-body d-block px-lg-0"
              style={{ border: "1px solid #ccc", marginTop: "100px" }}
            >
              <div className="card overflow-hidden" style={{ marginLeft: "20px" }}>
                <div className="d-flex flex-column">
                  <div style={{ margin: "20px" }}>
                    <span
                      className="userName font-weight-bold"
                      style={{ marginBottom: "20PX", marginLeft: "20px" }}
                    >
                      {userData ? userData.name : "Username"}
                    </span>
                  </div>
                </div>

                <div className="card-body py-0">
                  <hr style={{ margin: "0px" }} />
                  <ul
                    className="nav nav-link-secondary flex-column fw-bold gap-2"
                    style={{ fontSize: "larger" }}
                  >
                    <li className="nav-item">
                      <Link to="/">
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Home</span>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/userProfile">
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>My Profile</span>
                        </div>
                      </Link>
                    </li>
                    <hr style={{ margin: "0px" }} />
                    <li className="nav-item">
                      <Link to="/editProfile">
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Edit Profile</span>
                        </div>
                      </Link>
                    </li>
                    <hr style={{ margin: "0px" }} />
                    <li className="nav-item">
                      <Link to={{}}>
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Activity Log</span>
                        </div>
                      </Link>
                    </li>
                    <hr style={{ margin: "0px" }} />
                    <li className="nav-item">
                      <Link to={{}}>
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Create Issues</span>
                        </div>
                      </Link>
                    </li>
                    <hr style={{ margin: "0px" }} />
                    <li className="nav-item">
                      <Link to={{}}>
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Create Ticket</span>
                        </div>
                      </Link>
                    </li>
                    <hr style={{ margin: "0px" }} />
                    <li className="nav-item">
                      <Link to={{}}>
                        <div className="nav-align">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="me-2 h-20px fa-fw"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                            />
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Webmail</span>
                        </div>
                      </Link>
                    </li>
                    <hr style={{ margin: "0px" }} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default ProfileSidebar;
