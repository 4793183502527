import React from "react";
import Marquee from "react-fast-marquee";

const Album = () => {
  return (
    <>
      <Marquee pauseOnHover>
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          {[...Array(38)].map((e, i) => {
            return (
              <img
                key={i}
                height={150}
                src={`/assets/images/album${i + 1}.JPG`}
                alt=""
                style={{ objectFit: "cover" }}
              />
            );
          })}
        </div>
      </Marquee>
      <div className="instagream">
        <div className="instagram-feed clearfix">
          <div className="instagram-feed-user text-center">
            <div className="user-wrapper">
              <span className="icon-instagram">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </span>
              <span className="name-user">@bpdac</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Album;
