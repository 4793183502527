import Axios from "axios";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../components/Common/Header";

const Register = () => {
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: true,
    message: "",
  });
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);
  const navigate = useNavigate();

  // step-2
  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/;
    return regex.test(password);
  };

  // step-3
  const handlePasswordBlur = (e) => {
    const password = e.target.value;

    // Implement your password validation logic here
    const isValid = validatePassword(password);

    setPasswordValidation({
      isValid,
      message: isValid
        ? ""
        : "Password must be one letter, one digit, One Special Character and a minimum length of 7 characters.",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!passwordValidation.isValid) {
      return "Please Provide a strong password";
    }

    const formData = new FormData(e.target);
    try {
      const response = await Axios.post("https://bpdacadmin.icicle.dev/api/userRegister", formData);
      console.log(response);
      if (response.status === 200 && response.data.status === "ok") {
        Swal.fire({
          icon: "success",
          title: "Register successfully!",
          text: "Please check and verify your email",
          confirmButtonText: "Ok",
          confirmButtonColor: "#006837",
        }).then(() => {
          navigate("/login");
        });
      } else {
        Swal.fire({
          icon: "error",
          title:  "Registration failed",
          text: response.data.msg,
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
        //console.error("Registration failed:", response.data.msg);
      }
    } catch (error) {
      const errordata = {
        name: error.response.data.errors.name,
        email: error.response.data.errors.email,
        password: error.response.data.errors.password,
        confirm_password: error.response.data.errors.confirm_password,
      };
      Swal.fire({
        icon: "error",
        title: "Error submitting form",
        html: `
            ${errordata.name ? `<p>${errordata.name}</p>` : ""}
            ${errordata.email ? `<p>${errordata.email}</p>` : ""}
            ${errordata.password ? `<p>${errordata.password}</p>` : ""}
            ${errordata.confirm_password ? `<p>${errordata.confirm_password}</p>` : ""}
          `,

        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
      //console.error("Error submitting form:", error.response.data.errors);
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerifyCaptcha(true);
  }

  return (
    <>
      <Header />
      <div className="content-wrapper">
        <div className="account-page register text-center">
          <div className="container" id="reg">
            <div className="account-title">
              <h4 className="heading-light">REGISTRATION</h4>
            </div>
            <div className="account-content">
              <form onSubmit={handleSubmit}>
                <div className="input-box fullname">
                  <input
                    style={{ outline: "none" }}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Full Name"
                  />
                </div>
                <div className="input-box email">
                  <input
                    style={{ outline: "none" }}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                  />
                </div>

                <div className="input-box password">
                  <input
                    style={{ outline: "none" }}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onBlur={handlePasswordBlur}
                  />
                  {!passwordValidation.isValid && (
                    <div className="validation-message color-red" style={{ marginBottom: "10px" }}>
                      {passwordValidation.message}
                    </div>
                  )}
                </div>
                <div className="input-box confirm_password">
                  <input
                    style={{ outline: "none" }}
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm Password"
                  />
                </div>

                <div className="input-box recaptcha">
                  <ReCAPTCHA
                    sitekey="6LfwAyApAAAAAJAI-7MegdhFhNAu1dvU18E9XHOe"
                    onChange={onChange}
                  />
                </div>
                <div className="buttons-set">
                  <button
                    type="submit"
                    className="bnt bnt-theme text-regular text-uppercase"
                    disabled={!verifyCaptcha}
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
