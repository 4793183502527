import React from "react";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";

const Membership = () => {
  return (
    <>
      <Header />
      <div className="memory-page py-5">
        <div className="container">
          <h3 className="membership-heading text-center">
            The value that the membership provides is important. The following are the perks that
            the membership would provide:{" "}
          </h3>

          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-2"></div>

            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="membership-card mb-4">
                <div className="card-body">
                  <h5 className="card-title">
                    1. Support IMG in Residency or Practice Ready Pathway
                  </h5>
                  {/* Add more details or links as needed */}
                </div>
              </div>

              <div className="membership-card memberCard-even mb-4">
                <div className="card-body">
                  <h5 className="card-title">2. Support IMG in Alternative Career pathway </h5>
                  {/* Add more details or links as needed */}
                </div>
              </div>

              <div className="membership-card mb-4">
                <div className="card-body">
                  <h5 className="card-title">
                    3. Support Dentists in getting into Canadian registration{" "}
                  </h5>
                  {/* Add more details or links as needed */}
                </div>
              </div>

              <div className="membership-card mb-4 memberCard-even">
                <div className="card-body">
                  <h5 className="card-title">
                    4. Support Dentists in getting into allied pathways{" "}
                  </h5>
                  {/* Add more details or links as needed */}
                </div>
              </div>

              <div className="membership-card mb-4">
                <div className="card-body">
                  <h5 className="card-title">
                    5. Attendance in Professional Development classes/webinars/programs/discussions.{" "}
                  </h5>
                </div>
              </div>

              <div className="membership-card mb-4 memberCard-even">
                <div className="card-body">
                  <h5 className="card-title">
                    6. Networking with numerous Bangladeshi physicians and dentists who are
                    practicing in Canada
                  </h5>
                </div>
              </div>

              <div className="membership-card mb-4">
                <div className="card-body">
                  <h5 className="card-title">
                    7. Immediate support for new immigrant physicians concerning housing,
                    establishment in Canada and a lot more.{" "}
                  </h5>
                </div>
              </div>

              <div className="membership-card mb-4 memberCard-even">
                <div className="card-body">
                  <h5 className="card-title">
                    8. Support in getting resources to master the Canadian examinations.
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Membership;
