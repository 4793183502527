import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import {
  AdvocacyRepresentation,
  BiasReductionInitiatives,
  GlobalEngagement,
  InformationResourceSharing,
  SupportEmpowerment,
  activities,
} from "./datas";

const Services = () => {
  const [activeTab, setActiveTab] = useState("");
  const [selectedTab, setSelectedTab] = useState("principles");
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleSetActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleActivityClick = (activity) => {
    setSelectedActivity(activity);
  };

  return (
    <div className="programs-services">
      <div className="container">
        <div className="row">
          <div className="services-img col-md-6 col-sm-12 col-xs-12">
            <img className="img-responsive" src="/assets/images/about2nd.jpeg" alt="" />
          </div>
          <div className="services-content col-md-6 col-sm-12 col-xs-12">
            <div className="planPrinciple">
              <button
                className="btnClass"
                onClick={() => {
                  setSelectedTab("principles");
                  setActiveTab("");
                }}
              >
                Organization Principles
              </button>
              <button
                className="btnClass"
                onClick={() => {
                  setSelectedTab("activities");
                  setActiveTab("");
                }}
              >
                Planned Activities
              </button>
            </div>
            <div id="tab_services">
              {selectedTab === "principles" && (
                <ul className="nav principles-tabs" role="tablist">
                  <li onClick={() => handleSetActiveTab("support")}>
                    <a className="text-light" style={{ fontWeight: "bolder" }}>
                      Support and Empowerment
                    </a>
                  </li>
                  <li onClick={() => handleSetActiveTab("advocacy")}>
                    <a className="text-light" style={{ fontWeight: "bolder" }}>
                      Advocacy and Representation
                    </a>
                  </li>
                  <li onClick={() => handleSetActiveTab("information")}>
                    <a className="text-light" style={{ fontWeight: "bolder" }}>
                      Information and Resource Sharing
                    </a>
                  </li>
                  <li onClick={() => handleSetActiveTab("global")}>
                    <a className="text-light" style={{ fontWeight: "bolder" }}>
                      Global Engagement
                    </a>
                  </li>
                  <li onClick={() => handleSetActiveTab("bias-reduction-initiatives")}>
                    <a className="text-light" style={{ fontWeight: "bolder" }}>
                      Bias Reduction Initiatives
                    </a>
                  </li>
                </ul>
              )}

              {selectedTab === "activities" && (
                <>
                  <Marquee pauseOnHover>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                      }}
                    >
                      {activities.map((activity) => (
                        <div
                          key={activity.id}
                          onClick={() => {
                            handleSetActiveTab("");
                            handleActivityClick(activity);
                          }}
                          className="marquee-item"
                        >
                          <div>
                            <a
                              className="text-light"
                              style={{ padding: "10px", fontSize: "16px", fontWeight: "bolder" }}
                            >
                              {activity.name}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Marquee>

                  {selectedActivity && (
                    <div className="tab-content">
                      {/* <h3>{selectedActivity.name}</h3> */}
                      <p style={{ textAlign: "justify" }}>{selectedActivity.description}</p>
                    </div>
                  )}
                </>
              )}

              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active animated zoomIn" id="social">
                  <div className="tab-content-wrapper" style={{ textAlign: "justify" }}>
                    {activeTab === "support" && <SupportEmpowerment />}
                    {activeTab === "advocacy" && <AdvocacyRepresentation />}
                    {activeTab === "information" && <InformationResourceSharing />}
                    {activeTab === "global" && <GlobalEngagement />}
                    {activeTab === "bias-reduction-initiatives" && <BiasReductionInitiatives />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
