import axios from "axios";
import React, { useEffect, useState } from "react";
import History from "../components/AboutUs/History";
import Services from "../components/AboutUs/Services";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";
import Newsletter from "../components/Homepage/Newsletter";

const About = () => {
  //const [isListVisible, setListVisible] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [designations, setDesignations] = useState([]);
  // correction
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  // console.log(designations, 16)

  useEffect(() => {
    // Fetch team members data from the API
    axios
      .get("https://bpdacadmin.icicle.dev/api/ourTeam")
      .then((response) => {
        setTeamMembers(response.data);

        const uniqueDesignations = response.data.reduce((acc, item) => {
          // Check if the item's designation is not already included in the array
          if (!acc.includes(item.designation)) {
            acc.push(item.designation);
          }
          return acc;
        }, []);
        setDesignations(uniqueDesignations);
      })
      .catch((error) => {
        console.error("Error fetching team members:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedDesignation) {
      const filteredItems1 = teamMembers.filter((item) => item.designation === selectedDesignation);
      setFilteredItems(filteredItems1);
    }
  }, [selectedDesignation, teamMembers]);

  return (
    <>
      <Header />
      <div className="about-us">
        <div className="about-us-title text-center"></div>
        <div className="about-us-content">
          <div className="container">
            <div className="content-wrapper" style={{ textAlign: "center" }}>
              <h2 className="text-regular" style={{ color: "#2d2d2d" }}>
                Mission
              </h2>
              <p className="text-light" style={{ marginTop: "15px", textAlign: "justify" }}>
                Our mission is to represent, empower, and inspire Bangladeshi International Medical
                Graduates (IMGs) in Canada. We are committed to providing comprehensive resources,
                advocating for recognition, and navigating the Canadian healthcare system, we strive
                to make educational information accessible. Through negotiation and collaboration,
                we work towards policy changes for recognizing degrees issued by Bangladeshi
                authorities. Our overarching goal is to seamlessly integrate Bangladeshi IMGs into
                the Canadian health force, whether as physicians or allied health professionals,
                contributing significantly to the healthcare landscape. Simultaneously, we focus on
                providing health education to chronic disease patients, fostering community support
                services, and actively contributing to mental health and well-being. In times of
                international disasters, particularly in Bangladesh, we stand ready to provide
                relief and support. This collective effort fosters inclusivity, positivity,
                collaboration, and teamwork, contributing to a diverse and highly skilled healthcare
                workforce that meets the needs of the Canadian population and fulfills the
                aspirations of talented Bangladeshi medical professionals. Enhancing practicing
                physician networking, sharing knowledge, mitigating physician burnout, and
                ultimately improving healthcare for vulnerable Canadian communities.
              </p>
              <h2 className="heading-regular" style={{ color: "#2d2d2d" }}>
                Vision
              </h2>
              <p className="text-light" style={{ marginTop: "15px", textAlign: "justify" }}>
                Our vision is to cultivate an inclusive and supportive environment for Bangladeshi
                International Medical Graduates (IMGs) in Canada, fostering both professional and
                personal thriving. As a cornerstone for resources, advocacy, and collaboration, our
                aim is to create a community where every member not only feels at home and cared for
                but also receives comprehensive assistance in seamlessly assimilating into the
                Canadian healthcare workforce. Through a commitment to nurturing a profound sense of
                belonging and providing unparalleled support, we aspire to empower each IMG,
                enabling them to not only thrive and succeed in their professional pursuits but also
                contribute their unique skills and knowledge to the Canadian healthcare system in
                the most impactful and optimal manner.
              </p>
            </div>
          </div>
        </div>
        <div id="contacts" className="map">
          <div className="container">
            <div className="title-page">
              <div className="text-center">
                <h2 className="text-regular">We are not just Friend, We are Family</h2>
                <p className="text-light" style={{ marginBottom: "25px" }}>
                  {" "}
                  Signifies a deep, enduring connection that goes beyond friendship, emphasizing a
                  bond of care, support, and lasting relationships.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                    margin: "60px auto 20px auto",
                    fontSize: "3rem",
                    background: "#006837",
                    color: "white",
                    padding: "12px",
                  }}
                >
                  Our Team Member
                </div>
                <div>
                  {/* Buttons for different designations */}
                  {designations.map((item, idx) => (
                    <div key={idx}>
                      <button
                        className="team-button"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr",
                          alignItems: "center",
                          gap: "6px",
                          margin: "10px auto",
                          border: "1px solid green",
                        }}
                        key={item}
                        onClick={() => {
                          if (selectedDesignation === item) {
                            setSelectedDesignation("");
                          } else {
                            setSelectedDesignation(item);
                          }
                        }}
                      >
                        {!(selectedDesignation === item) ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            style={{
                              height: "18px",
                              width: "18px",
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            style={{
                              height: "18px",
                              width: "18px",
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        )}
                        {item}
                      </button>

                      {selectedDesignation === item && (
                        <div className="team-members-container">
                          {" "}
                          {filteredItems.map((teamMember) => (
                            <div key={teamMember.id} className="team-member-name">
                              {teamMember.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {/* {isListVisible && (
                                    <div className='team-members-container'>
                                        {teamMembers.map(member => (
                                            <div key={member.id} className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className='team-member-item' >
                                                        <div>
                                                            <img className='team-member-image' src={`https://bpdacadmin.icicle.dev/public/${member.image}`} alt={member.name} />
                                                        </div>
                                                        <div style={{
                                                            width: '100%',
                                                            padding: '10px'
                                                        }}>
                                                            <h5 style={{ marginBottom: '10px' }}>{member.designation}</h5>
                                                            <h3 style={{ marginBottom: '10px' }}>{member.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Services />
      <History />
      <Newsletter />
      <Footer />
    </>
  );
};

export default About;
