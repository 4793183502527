import Axios from "axios";
import React, { useEffect, useState } from "react";

const Block = () => {
  const [eventData, setEventData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get("https://bpdacadmin.icicle.dev/api/eventData");
        setEventData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get("https://bpdacadmin.icicle.dev/api/userData");
        setUserData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Sort events by start_date in descending order
  const sortedEvents = [...eventData].sort(
    (a, b) => new Date(b.start_date) - new Date(a.start_date)
  );

  const formatWeekday = (date) => {
    const options = { weekday: "short" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  return (
    <div className="block-links">
      <div className="container">
        <div className="row">
          <div className="block-news col-md-4 col-sm-12 col-xs-12">
            <div className="column-news">
              <div className="title-links">
                <h3 className="heading-regular">Latest News</h3>
              </div>
              <div className="post-wrapper members-directory">
                <div className="post-item clearfix ">
                  <div className="image-frame post-photo-wrapper">
                    <a href="#.">
                      {" "}
                      <img
                        src="assets/images/upcoming_family_night.jpg"
                        alt=""
                        style={{ height: "100px" }}
                      />
                    </a>
                  </div>
                  <div className="post-desc-wrapper">
                    <div className="post-desc">
                      <div className="post-title">
                        <h6 className="heading-regular">
                          <a href="#.">Upcoming Family Night</a>
                        </h6>
                      </div>
                      <div className="post-excerpt">
                        <p>Registration is open for the upcoming family night on Feb 18, 2024</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-item clearfix ">
                  <div className="image-frame post-photo-wrapper">
                    <a href="#.">
                      {" "}
                      <img
                        src="assets/images/artCompitition.jpg"
                        alt=""
                        style={{ height: "100px" }}
                      />
                    </a>
                  </div>
                  <div className="post-desc-wrapper">
                    <div className="post-desc">
                      <div className="post-title">
                        <h6 className="heading-regular">
                          <a href="#.">Children's Art Competition Submission</a>
                        </h6>
                      </div>
                      <div className="post-excerpt">
                        <p>
                          Deadline Approaching: Children's Art Competition Submission - February
                          5th, 2024
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-item clearfix ">
                  <div className="image-frame post-photo-wrapper">
                    <a href="#.">
                      {" "}
                      <img src="assets/images/cultural-night.jpg" alt="" />
                    </a>
                  </div>
                  <div className="post-desc-wrapper">
                    <div className="post-desc">
                      <div className="post-title">
                        <h6 className="heading-regular">
                          <a href="#.">BPDAC Night Cultural Program</a>
                        </h6>
                      </div>
                      <div className="post-excerpt">
                        <p>
                          Submit Attendee Names for BPDAC Night Cultural Program by December 25th,
                          2023
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-item clearfix ">
                  <div className="image-frame post-photo-wrapper">
                    <a href="#.">
                      {" "}
                      <img src="assets/images/cultural_rehearsals.jpg" alt="" />
                    </a>
                  </div>
                  <div className="post-desc-wrapper">
                    <div className="post-desc">
                      <div className="post-title">
                        <h6 className="heading-regular">
                          <a href="#.">Cultural Program Rehearsals</a>
                        </h6>
                      </div>
                      <div className="post-excerpt">
                        <p>Cultural Program Rehearsals Begin During Christmas Break</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="post-item clearfix ">
                  <div className="image-frame post-photo-wrapper">
                    <a href="#.">
                      {" "}
                      <img src="assets/images/family_nights.jpg" alt="" />
                    </a>
                  </div>
                  <div className="post-desc-wrapper">
                    <div className="post-desc">
                      <div className="post-title">
                        <h6 className="heading-regular">
                          <a href="#.">BPDAC Family Night</a>
                        </h6>
                      </div>
                      <div className="post-excerpt">
                        <p>
                          Secure Your Spot for BPDAC Family Night - Registration Deadline: January
                          31st, 2024
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-all">
                <a href="#.">View All News</a>
              </div>
            </div>
          </div>
          <div className="block-career col-md-4 col-sm-12 col-xs-12">
            <div className="column-career ">
              <div className="title-links">
                <h3 className="heading-regular">Member Directory</h3>
              </div>
              <div className="career-content members-directory">
                {userData
                  .filter((item) => {
                    return item.image !== null;
                  })
                  .map((user) => {
                    if (user.status === "approved") {
                      return (
                        <div
                          key={user.id}
                          className="company-item clearfix"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="company-logo">
                            <img
                              src={`https://bpdacadmin.icicle.dev/public/${user.image}`}
                              alt={user.name}
                            />
                          </div>
                          <div className="company-desc-wrapper">
                            <div className="company-desc">
                              <div className="company-title">
                                <h6 className="heading-regular">
                                  <a href="#.">{user.name}</a>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
              <div className="view-all">
                <a href="#.">View All Member Directory</a>
              </div>
            </div>
          </div>
          <div className="block-event-calendar col-md-4 col-sm-12 col-xs-12">
            <div className="column-calendar">
              <div className="title-links">
                <h3 className="heading-regular">Event Calendar</h3>
              </div>
              <div className="content-calendar bg-calendar no-padding">
                <div className="list-view members-directory">
                  {sortedEvents?.map((event, index) => (
                    <div key={index} className="view-item">
                      <div className="date-item">
                        <span className="date-time dates text-light">
                          {formatWeekday(event.start_date)}
                        </span>
                        <span className="date-time day text-bold color-theme">
                          {new Date(event.start_date).getDate()}
                        </span>
                        <span className="date-time month text-light">
                          {new Date(event.start_date).toLocaleString("en-US", { month: "short" })}
                        </span>
                      </div>
                      <div className="date-desc-wrapper">
                        <div className="date-desc">
                          <div className="date-title">
                            <h6 className="heading-regular">{event.title}</h6>
                          </div>
                          <div className="date-excerpt">
                            <p>Organizer: {event.description.slice(0, 65) + "..."}</p>
                          </div>
                          <div className="place">
                            <span className="icon map-icon"></span>
                            <span className="text-place">{event.venue}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="view-all">
                <a href="#.">View All Events</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block;
