// EventPage.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Grid } from "@material-ui/core";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";

function EventPage() {
  const { eventId } = useParams();
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const { data } = await axios.get(`https://bpdacadmin.icicle.dev/api/eventData`);
        // setEventDetails();
        setEventDetails(data.find((event) => Number(event.id) === Number(eventId)));
      } catch (error) {
        console.log("Error fetching event details:", error.response.data.message);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  // console.log(eventDetails);

  return (
    <>
      <Header />
      <div className="memory-page">
        {eventDetails && (
          <>
            <Container className="py-12">
              <Typography variant="h4" align="center" style={{ paddingBottom: "20px" }}>
                {eventDetails?.title}
              </Typography>
              <Grid container spacing={2}>
                {JSON.parse(eventDetails?.memories).map((memory, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <div className="image-container">
                      <img
                        src={`https://bpdacadmin.icicle.dev/${memory}`}
                        alt={`Memory ${index + 1}`}
                        style={{ width: "100%", height: "280px", objectFit: "cover" }}
                      />
                      <div className="hover-overlay" />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default EventPage;
