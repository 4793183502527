import { Container } from "@material-ui/core";
import Axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import ProfileSidebar from "./ProfileSidebar";

const EditProfile = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const token = localStorage.getItem("token");

    try {
      const response = await Axios.post("https://bpdacadmin.icicle.dev/api/userUpdate", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Edited successfully!",
          text: "Your profile has been edited successfully",
          confirmButtonText: "Ok",
          confirmButtonColor: "#006837",
        }).then(() => {
          navigate("/userProfile");
        });
        e.target.reset();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.msg,
          confirmButtonText: "Ok",
          confirmButtonColor: "red",
        });
      }
      //console.log(response);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        confirmButtonText: "Ok",
        confirmButtonColor: "red",
      });
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Header />
      <Container className="memory-page py-12">
        <ProfileSidebar />
        <div className="col-lg-8 col-md-8 vstack gap-4">
          <div className="card profile-info">
            <div className="card-header border-0 pb-0">
              <div className="text-center">
                <h4 className="card-title" style={{ margin: "20px", fontWeight: "bold" }}>
                  Edit Profile
                </h4>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter your username"
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="tel"
                    className="form-control"
                    id="'specialist"
                    name="specialist"
                    placeholder="Enter your speciality"
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="working_place"
                    name="working_place"
                    placeholder="Enter your workplace"
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <textarea
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Enter your address"
                    rows="3"
                  />
                </div>
                <div className="mb-3" style={{ margin: "20px" }}>
                  <input className="form-control" type="file" id="image" name="image" />
                </div>

                <button type="submit" className="btn btn-primary" style={{ margin: "20px" }}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default EditProfile;
