import React from "react";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";
import Album from "../components/Homepage/Album";
import Block from "../components/Homepage/Block";
import Events from "../components/Homepage/Events";
import Sliders from "../components/Homepage/Slider";
import LatestEventWithMemories from "../components/Homepage/LatestEventWithMemories";


const Homepage = () => {
    return (
      <>
          <div className="main-wrapper">
            <Header />
          </div>
          <div className="content-wrapper">
            <Sliders />    
            <Events />
            <LatestEventWithMemories />
            {/* <Dashboard /> */}
            <Block />
            <Album />
          </div> 
        <Footer />
       </>
   );
}

export default Homepage;